import React from "react";
import Slider from "react-slick";
import ghanshyam from './assets/image/profile-ghanshyam.jpg'
import brijesh from './assets/image/brijesh.jpg'
import deep from './assets/image/deep.jpg'
import kenil from './assets/image/kenil.jpg'
import coma from './assets/image/coma.png'

const Team = () => {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div>
            <section className="py-4 px-3 px-md-0 bg-white">
                <div className="container px-0">
                    <div className="row">
                        <div className="row text-center">
                            <h2 className="text-dark pb-10 fw-semibold">
                                Our Team
                            </h2>
                            <p className>
                                Don't just take our word for the level of service we provide - read what our buyers and
                                <br />sellers have to say!
                            </p>
                        </div>
                        <div className="col-lg-12 ">
                            <Slider {...settings}>
                                <div>
                                    <div className="shadow-box  p-25 m-2">
                                        <div className=" d-flex justify-content-between">
                                            <div className="testimonial-info">
                                                <div className="image d-flex align-self-center align-items-center">
                                                    <img src={ghanshyam} className="w-25 h- rounded-circle " alt="Ghanshyam Kumbhani" />
                                                    <div className="meta ms-2">
                                                        <h6>Ghanshyam Kumbhani</h6>
                                                        <p>Founder & CEO</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="quote w-25">
                                                <img src={coma} alt="" />
                                            </div>
                                        </div>
                                        <div className="content mt-10">
                                            <p>ghanshyam Kumbhani is the visionary founder and CEO of Liontouch webpark Innovations, a pioneering company specializing in cutting-edge web development using the MERN stack. With a passion for technology and innovation, John has established himself as a leader in the web development industry.
                                                
                                                {/* I am a MERN developer with polished skills in all of its major libraries such as React-Redux, React Router, Ant design, Material UI, etc. I have also a strong grip over React Hooks. I have developed several websites. I am also a NodeJs developer. */}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="shadow-box  p-25 m-2">
                                        <div className=" d-flex justify-content-between">
                                            <div className="testimonial-info">
                                                <div className="image d-flex align-self-center align-items-center">
                                                    <img src={brijesh} className="w-25 rounded-circle " alt="Brijesh Bhesaniya" />
                                                    <div className="meta ms-2">
                                                        <h6>Brijesh Bhesaniya </h6>
                                                        <p>Frontend Developer</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="quote w-25">
                                                <img src={coma} alt="" />
                                            </div>
                                        </div>
                                        <div className="content mt-10">
                                            <p>I'm a front end developer with two years of experience in web development. I've worked on a variety of projects for clients in the retail, travel, and healthcare industries. I'm passionate about creating great user experiences and have a strong...ite
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className=" shadow-box  p-25 m-2">
                                        <div className=" d-flex justify-content-between">
                                            <div className="testimonial-info">
                                                <div className="image d-flex align-self-center align-items-center">
                                                    <img src={deep} className="w-25 h- rounded-circle " alt="Deep Bhesdadiya" />
                                                    <div className="meta ms-2">
                                                        <h6>Deep Bhesdadiya </h6>
                                                        <p>Flutter Developer</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="quote w-25">
                                                <img src={coma} alt="" />
                                            </div>
                                        </div>
                                        <div className="content mt-10">
                                            <p>I have Flutter Developer with 1.5+ years of experience,who is comfortable working with android,ios and other platforms with responsive and attrective ui design to deliver exceptional customer experience. and have other skills like Firebase, sqlite..
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className=" shadow-box  p-25 m-2">
                                        <div className=" d-flex justify-content-between">
                                            <div className="testimonial-info">
                                                <div className="image d-flex align-self-center align-items-center">
                                                    <img src={kenil} className="w-25 h- rounded-circle " alt="Deep Bhesdadiya" />
                                                    <div className="meta ms-2">
                                                        <h6>Kenil Vaghasiya </h6>
                                                        <p>MERN Stack Developer</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="quote w-25">
                                                <img src={coma} alt="" />
                                            </div>
                                        </div>
                                        <div className="content mt-10">
                                            <p>I am a ReactJs developer with polished skills in all of its major libraries such as React-Redux, React Router, Ant design, Material UI, etc. I have also a strong grip over React Hooks. I have developed several websites. I am also a NodeJs developer.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                             </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );

}

export default Team