import React, { useState } from 'react'
// import ecommers from './assets/image/webdevlopment.jpg';
import webdevelop from './assets/image/web-develop.png'
import appdevelop from './assets/image/app-develop.png'
import mobileaapp from './assets/image/app-developement.png';
import Marketing from './assets/image/marketing.jpg';
import webdevelp from './assets/image/webdevlopment.jpg';
import webblog from './assets/image/Business Development Advice.jpg';
import appblog from './assets/image/blog-app.jpg';
import Uiblog from './assets/image/high-angle-designer-holding-smartphone.jpg';
import Footer from './Footer';
import Header from './Header';
import { Link } from 'react-router-dom';
import Ourservices from './Ourservices';
import firebaseApp from './Firecontact';
import { ToastContainer, toast } from 'react-toastify'; 
import Footerbrand from './Footerbrand';

let data = [];
const Home = () => {
    const [fname, setFname] = useState('')
    const [email, setEmail] = useState('')
    const [number, setNumber] = useState('')
    const [pname, setPname] = useState('')
    const [Inquriydatad, SetInquriy] = useState([])


    const validateEmail = (email) => {
        // Use a regex pattern for basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePhoneNumber = (phoneNumber) => {
        // Use a regex pattern for basic phone number validation
        const phoneRegex = /^[0-9]+$/;
        return phoneRegex.test(phoneNumber);
    };

    const SendDb = () => {
        let Inquerydata = {
            Fname: fname,
            Email: email,
            Number: number,
            Pname: pname,
            id: Date.now(),
            Date: Date()
        }
        if (fname === '') {
            alert('Please enter first name');
            return;
        }
        if (!validateEmail(email)) {
            alert('Please enter a valid email address');
            return;
        }
        if (!validatePhoneNumber(number)) {
            alert('Please enter a valid phone number 0-9 & 10 Character');
            return;
        }
        if (pname === '') {
            alert('Please enter a Project');
            return;
        }
        data.push(Inquerydata)
        console.log(Inquerydata);
        let db = firebaseApp.firestore()
        db.collection("Inquriy").add(Inquerydata)
            .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
                toast("Thankyou For Send");

            })
            .catch((error) => {
                console.error("Error adding document: ", error);
                toast("Plas Try Agin");

            });

        SetInquriy(data);
        setFname('')
        setEmail('')
        setNumber('')
        setPname('')
    }
    return (
        <div className='bg-gray'>
            <Header name='home' />
            <ToastContainer />
            <section className="bg-color px-3 px-md-0">
                <div className="container py-3 py-md-5">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 align-self-center ps-0">
                            <h5 className="pt-15 pb-2 text-white  text-center text-md-start" style={{ fontSize: '20px' }}>
                                Happy on Business</h5>
                            <div>
                                <h1 className="text-white fw-600 text-center text-md-start" style={{ fontSize: '54px' }}>
                                    Business <span className="text-orange">Growth
                                    </span></h1>
                                <h3 className="pt-10 text-white text-center text-md-start fw-400 lh-1  ">
                                    Start Advertising with us Today and Grow Your Business. </h3>
                                <p className="mt-10 text-white text-center text-md-start">Start advertising your Business opportunity with Create a Website today to boost your Business recruitment and attract more high quality for your business at a lower cost. <span className="text-orange">With Liontouch Webpark</span></p>
                            </div>
                            <div className="row mt-20 mt-md-3">
                                <div className="col-lg-4  ms-2 ms-md-0 d-grid">
                                    <Link to={'/About-Us'} className="btn-history text-white py-2" >
                                        <i class="lni lni-bookmark-alt pe-2 "></i> Our Vision
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col justify-content-end">
                            <div className="text-end">
                                <p className=" mt-20 mt-lg-0 text-center text-light">LET'S DISCUSS YOUR
                                    REQUIREMENT</p>
                                <div className='text-center'>
                                    <form action="#" className="mt-20 text-center text-md-end">
                                        <div className="form-group mb-20">
                                            <input type="text" id="fname" onChange={(e) => setFname(e.target.value)} value={fname} placeholder="Full Name*" />
                                        </div>
                                        <div className="form-group mb-20">
                                            <input type="email" id="email" onChange={(e) => setEmail(e.target.value)} value={email} name="email" placeholder="Email address *" />
                                        </div>
                                        <div className="form-group mb-20">
                                            <input maxLength={10} onChange={(e) => setNumber(e.target.value)} value={number} name="phone" placeholder="Phone number *" />
                                        </div>
                                        <div className="form-group mb-20">
                                            <input type="text" onChange={(e) => setPname(e.target.value)} value={pname} name="project" placeholder="Project" />
                                        </div>
                                        <div className="form-groupx mb-20">
                                            <button type="button" className="btn-main w-100 " onClick={SendDb} >
                                                Send
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-white py-4 py-md-5">
                <div className="container  px-3 p-lg-0">
                    <div className="row  justify-content-between align-items-center">
                        <div className="col-lg-5 col-md-6 col-12  order-2 order-md-1">
                            <h4 className="mb-20 text-dark fw-semibold">Software Solution Services</h4>
                            <p className="mb-10">There is no minimum requirement to get in touch with us. Even though you just have a random thought about your next technical solution and want to discuss it then also you are welcome. Do come and share your tech ideas , we will help you to convert them into software solutions.
                            </p>
                            <p className='pb-10'>
                                Utilize social media and email campaigns for targeted promotions. Implement robust customer support systems, ensuring a positive post-purchase experience.
                            </p>
                            <Link to={'/contact'} className="btn-main ">Explore Liontouch</Link>
                        </div>
                        <div className="col-lg-6 col-md-6 order-1 order-md-2 text-center text-lg-end py-3 py-md-0">
                            <img src={webdevelp} className="w-75 " alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-4 py-md-5">
                <div className="container  px-3 px-md-0">
                    <div className="row  justify-content-between align-items-center">
                        <div className="col-lg-6 col-md-6 col-12   text-center py-3 py-md-0  ">
                            <img src={mobileaapp} className="w-75" alt="" />
                        </div>
                        <div className="col-lg-5 col-md-6 col-12 align-self-center">
                            <h4 className="mb-20 text-dark fw-semibold">Mobile Web Design on your Business</h4>
                            <p className="mb-10">Mobile web design is critical for the success of any business in today's digital landscape, given the widespread use of smartphones. Ensuring your website is optimized for mobile devices is not just about responsivenes, it's about delivering a seamless and engaging user experience.
                            </p>
                            <li>Clear Purpose</li>
                            <li>Security Measures</li>
                            <li>Speed Test</li>
                            <li>Product Module</li>

                            <div className="mt-20 ">
                                <Link to={'/contact'} className="btn-main">Now join Ourteam</Link>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="bg-white py-4 py-md-5">
                <div className="container  px-3 p-lg-0">
                    <div className="row  justify-content-between align-items-center">
                        <div className="col-lg-5 col-md-6 col-12  order-2 order-md-1">
                            <h4 className="mb-20 text-dark fw-semibold">Ui & Digital Marketing</h4>
                            <p className="mb-10"> Mobile web design is critical for the success of any business in today's digital landscape, given the widespread use of smartphones. Ensuring your website is optimized for mobile devices is not just about responsivenes, it's about delivering a seamless and engaging user experience.
                            </p>
                            <li>User-Centric Design</li>
                            <li>Loading Speed</li>
                            <li>Visual Appeal</li>
                            <li>Content Readability</li>
                            <Link to={'/contact'} className="btn-main  mt-20">Explore Liontouch</Link>
                        </div>
                        <div className="col-lg-6 col-md-6 order-1 order-md-2 text-center text-lg-end py-3 py-md-0">
                            <img src={Marketing} className="w-75" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-4">
                <div className="container ">
                    <div className="row">
                        <div className="col-lg-1 col-md-1 align-self-center">
                        </div>
                        <div className="col-lg-10 col-md-12 text-center">
                            <h2 className="text-center fw-bold fs-1 text-dark">
                                Welcome to <span className="text-orange">Business  Growth</span>
                            </h2>
                            <div className="d-flex justify-content-center mt-10">
                                <p className="text-dark fw-500 px-3">Find Customer </p>
                                <span className="botd" />
                                <p className="text-dark fw-500 px-3">Generate Leads</p>
                                <span className="botd" />
                                <p className="text-dark fw-500 px-3">Sell Product</p>
                            </div>
                            <p className="text-center   mb-10"> Identify your target audience, conduct market research, establish an online presence, create valuable content, and engage in networking activities to locate potential customers. & Offer incentives, use email marketing, leverage social media, encourage referrals, and host events or webinars to attract and capture potential leads.
                            </p>
                        </div>
                        <div className="col-lg-1 col-md-1 align-self-center">
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-lg-9 col-md-12">
                            <div className="shadow-box bg-color p-30 mt-20">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8">
                                        <div>
                                            <h3 className="text-light fs-3 lh-1 mb-20 fw-md text-center text-md-start">Why Create Websites?
                                            </h3>
                                        </div>
                                        <div className="ps-3">
                                            <div className="row">
                                                <div className="col-lg-6">  <p className="text-white d-flex mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                    Online Presence</p>
                                                    <p className="text-white d-flex mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                        Marketing and Branding</p>
                                                    <p className="text-white d-flex  mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                        Communication and Engagement</p></div>
                                                <div className="col-lg-6">  <p className="text-white d-flex mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                    Sales and E-commerce</p>
                                                    <p className="text-white d-flex mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                        Analytics and Insights</p>
                                                    <p className="text-white d-flex mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                        Global Reach and Accessibility</p></div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 d-flex justify-content-end  mt-20 mt-lg-0">
                                        <div className=" w-100 ">
                                            <Link to={'/About-us'} className="btn-main  w-100  ">Build Business
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pt-15 d-none">
                                    <div className="col-lg-3 col-md-3 col-6 text-center">
                                        <h3 className="fw-bold text-white">10M</h3>
                                        <p className="text-white fs-14 lh-sm">Active job seekers</p>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-6 text-center  ">
                                        <h3 className="fw-bold text-white">4000+</h3>
                                        <p className="text-lg-start text-white fs-14 lh-sm">New job seekers
                                            joining today</p>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-6 text-center ">
                                        <h3 className="fw-bold text-white">75%</h3>
                                        <p className=" text-lg-start text-white fs-14 lh-sm">of job matches happen
                                            within 24 hours</p>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-6 text-center text-light ">
                                        <h3 className="fw-bold text-white">1M+</h3>
                                        <p className="text-lg-start text-white fs-14 lh-sm">employers globally trust </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-12  d-none d-lg-inline align-self-center justifty-contant-center  ">
                            <img src={webdevelop} alt='' />

                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-lg-3 col-md-12    d-none d-lg-inline align-self-center  ">
                            <img src={appdevelop} alt='' />

                        </div>
                        <div className="col-lg-9 col-md-12">
                            <div className="shadow-box bg-color p-30 mt-20">
                                <div className="row ">
                                    <div className="col-lg-8 col-md-8">
                                        <div>
                                            <h3 className="fs-3 lh-1 mb-20 text-white fw-md text-center text-md-start">
                                                Why Create Applications?</h3>
                                        </div>
                                        <div className="ps-3">
                                            <div className="row">
                                                <div className="col-lg-6">  <p className="text-white d-flex mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                    Enhanced User Experience    </p>
                                                    <p className="text-white d-flex mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                        Access to Device Features</p>
                                                    <p className="text-white d-flex mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                        Direct Communication and Engagement</p></div>
                                                <div className="col-lg-6">  <p className="text-white d-flex mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                    Monetization and Business Opportunities</p>
                                                    <p className="text-white d-flex mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                        Specialized Functionalities</p>
                                                    <p className="text-white d-flex mt-10"> <span className="me-2"><i class="lni lni-angle-double-right"></i></span>
                                                        Data Collection and Insights</p></div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 d-flex justify-content-end  mt-20 mt-lg-0">
                                        <div className="w-100">
                                            <Link to={'/About-us'} className="btn-main w-100 rounded-2">Build Application</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="row pt-15 d-none">
                                    <div className="col-lg-3 col-md-3 col-6 text-center">
                                        <h3 className="text-orange fw-bold">10M</h3>
                                        <p className="text-white fs-14 lh-sm">Active job seekers</p>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-6 text-center">
                                        <h3 className="text-orange fw-bold">4000+</h3>
                                        <p className="text-lg-start text-white fs-14 lh-sm">New job seekers
                                            joining today</p>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-6 text-center">
                                        <h3 className="text-orange fw-bold">75%</h3>
                                        <p className="text-lg-start text-white fs-14 lh-sm">of job matches happen
                                            within 24 hours</p>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-6 text-center">
                                        <h3 className="text-orange fw-bold">1M+</h3>
                                        <p className="text-lg-start text-white fs-14 lh-sm"> employers globally trust </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Ourservices />
            <section className="py-4 bg-gray px-2 px-lg-0">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="text-center">
                            <h2 className="fw-semibold">Guides In Business Development</h2>
                            <p className="d-flex justify-content-center mt-15 mb-20">Identify a specific niche or industry where you can excel.Emphasize the importance of responsive web design.Design websites with scalability in mind for future growth.</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-9 col-sm-6 mb-3  ">
                            <div className="bg-white rounded-2 shadow-box d-grid   p-3">
                                <div><img src={webblog} className="rounded-1  " alt="webdevelopment" /></div>
                                <div className=" mt-3">
                                    <h5>
                                        Sell Fast, Lowcost Commission
                                    </h5>
                                    <hr className="border-orange w-25 opacity-100" />
                                    <p className="lh-sm text-start">When offering web development services, effective communication with clients, attention to detail, and staying abreast of industry trends are essential for success. Additionally, consider building a portfolio showcasing your previous projects to demonstrate your capabilities to potential clients.
                                    </p>
                                    <Link to={"/Contact"} className="btn btn-dark  px-4 mt-20 d-grid">Create Website on Business</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-9 col-sm-6 mb-3    ">
                            <div className="bg-white rounded-2 shadow-box d-grid   p-3">
                                <div><img src={appblog} className="rounded-1   " alt="Integrate AI Development" /></div>
                                <div className=" mt-3">
                                    <h5>
                                        Integrate AI  Development
                                    </h5>
                                    <hr className="border-orange   w-25 opacity-100" />
                                    <p className="lh-sm text-start">When integrating AI into app development, it's crucial to consider data privacy, transparency, and user consent. Additionally, continuous monitoring and refinement of AI models based on user feedback and evolving needs are essential for long-term success.</p>
                                    <Link to={"/Contact"} className="btn btn-dark mt-20 d-grid" >Create App on Business</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-9 col-sm-6   ">
                            <div className="bg-white rounded-2 shadow-box d-grid   p-3">
                                <div><img src={Uiblog} className="rounded-1  " alt="UI Development" /></div>
                                <div className="mt-3">
                                    <h5>
                                        UI Development, Established Brand
                                    </h5>
                                    <hr className="border-orange w-25 opacity-100" />
                                    <p className="lh-sm text-start">Open a business with an established brand and working business
                                        model , UI and UX development is an ongoing process that requires collaboration between designers, developers and iterate on your designs to create a compelling and user-centric application.
                                    </p>
                                    <Link to={"/Contact"} className="btn btn-dark mt-20 d-grid">Build Brand Business</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footerbrand />
            <Footer />
        </div >
    )
}
export default Home
