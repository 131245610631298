import React from 'react'
import Design from './assets/image/design.png';
import customdesign from './assets/image/customdesign.png';
import WebsiteMaintenance from './assets/image/Website-mintenance.png';
import ECommerce from './assets/image/E-Commerce.png';
import Social from './assets/image/Social.png';
import Secure from './assets/image/Secure.png';

const Ourservices = () => {
  return (
    <section className="bg-white pt-35 pb-35">
    <div className="container">
      <div className="row pb-10 d-flex justify-content-center ">
        <div className="col-md-10 mb-20 text-center">
          <h2 className="fw-semibold pt-0">
            Our Services
          </h2>

        </div>
      </div>
      <div className="row text-center| layout">
        <div className="col-xl-4 col-lg-4 col-md-6 mb-4">
          <div className="shadow-box p-3 d-flex h-100">
            <div className="card-body text-center">
              <img src={Design} className="pb-20 w-25" alt="Responsive Web Design" />
              <h4 className="fs-5 fw-500 mb-2 text-dark">Responsive Web Design</h4>
              <p className="lh-sm fw-light">Responsive web design adapts a website's layout and elements to seamlessly fit various devices and screen sizes.</p>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 mb-4">
          <div className="shadow-box p-3 d-flex h-100">
            <div className="card-body text-center">
              <img src={customdesign} className="pb-20 w-25" alt="Custom Web Design" />
              <h4 className="fs-5 fw-500 mb-2 text-dark">Custom Web Design</h4>
              <p className="lh-sm fw-light">Custom web design involves tailoring a website's visual and functional elements to meet specific needs.
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 mb-4">
          <div className="shadow-box p-3 d-flex h-100">
            <div className="card-body text-center">
              <img src={WebsiteMaintenance} className="pb-20 w-25" alt="Website Maintenance" />
              <h4 className="fs-5 fw-500 mb-2 text-dark">Website Maintenance</h4>
              <p className="lh-sm fw-light">Website maintenance encompasses routine tasks to sustain a site's performance and relevance.</p>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 mb-4">
          <div className="shadow-box p-3 d-flex h-100">
            <div className="card-body text-center">
              <img src={ECommerce} className="pb-20 w-25" alt="E-Commerce" />
              <h4 className="fs-5 fw-500 mb-2 text-dark">E-Commerce</h4>
              <p className="lh-sm fw-light">E-commerce design aims to drive sales by optimizing navigation and showcasing products.
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 mb-4">
          <div className="shadow-box p-3 d-flex h-100">
            <div className="card-body text-center">
              <img src={Social} className="pb-20 w-25" alt="Social Following" />
              <h4 className="fs-5 fw-500 mb-2 text-dark">Social Following</h4>
              <p className="lh-sm fw-light">As well as our web presence we also have a large social following
                to promote to As well as our web presence we also have a large social following
                to promote to.</p>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 mb-4">
          <div className="shadow-box p-3 d-flex h-100">
            <div className="card-body text-center">
              <img src={Secure}className="pb-20 w-25" alt="Secure" />
              <h4 className="fs-5 fw-500 mb-2 text-dark">Secure</h4>
              <p className="lh-sm fw-light">We work hard to provide good quality leads from interested buyers
                that you can convert We work hard to provide good quality leads from interested buyers
                that you can convert.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Ourservices