import React from 'react'
import Header from './Header'
import Footer from './Footer'
 import costcuru from './assets/image/luxury-modern-bedroom-with-comfortable.jpg';
 import nfccard from './assets/image/NFC card.jpg';

 import { Link } from 'react-router-dom';

const Product = () => {

  return (
    <div style={{ backgroundColor: '#f7f7fc' }}>
      <Header name='Product' />
      <section className='py-3 pt-4' style={{ backgroundColor: '#f7f7fc' }}>
        <div class="container">
          <div class="d-flex justify-content-between align-items-center align-self-center">
            <h3 className="text-dark fw-600 d-none d-md-block pb-0 ">
              Product <span className="text-orange">Us
              </span></h3>
            <ol className='d-flex justify-content-center align-items-center '>
              <li>
                <Link to={'/Home'} className='text-orange'>Home</Link> </li> <span className='mx-2'>/</span>
              <li> Product </li>
            </ol>
          </div>
        </div>
      </section>
      <section className="bg-white py-4 py-md-5">
        <div className="container  px-3 p-lg-0">
          <div className="row  justify-content-between align-items-center">
            <div className="col-lg-5 col-md-6 col-12  order-2 order-md-1">
              <h4 className="mb-20 text-dark fw-semibold">NFC Card Services</h4>
              <p className="mb-10">NFC Business Cards enable instant sharing of contact information with a simple tap or scan.
              </p>
              <p className='pb-10'>
              Share business profile and contact info easily by tapping card on devices. No app needed, saves time and makes connections quickly.
              </p>
              <a href="https://9rnfc.com" className="btn-main " target='_blank'>NFC Card</a>
            </div>
            <div className="col-lg-6 col-md-6 order-1 order-md-2 text-center text-lg-end py-3 py-md-0">
              <img src={nfccard} className="w-75 " alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white py-4 py-md-5">
        <div className="container  px-3 p-lg-0">
          <div className="row  justify-content-between align-items-center">
            <div className="col-lg-5 col-md-6 col-12  order-2 order-md-1 text-center text-md-start">
            <img src={costcuru} className="w-75 " alt="" />
              
            </div>
            <div className="col-lg-6 col-md-6 order-1 order-md-2     py-3 py-md-0">
            <h4 className="mb-20 text-dark fw-semibold">Curtain  Management</h4>
              <p className="mb-10">Create a compelling headline that clearly states the primary benefit of your software, such as "Streamline Your Curtain Management with Our Advanced Calculator Software.
              </p>
              <p className='pb-10'>
              "Save time by automating complex calculations" or "Ensure accurate fabric orders and minimize waste."
              </p>
              <a href="https://costguru-app.web.app/" className="btn-main " target='_blank'>Cost Guru</a>
            </div>
          </div>
        </div>
      </section>
      {/* <Footerbrand /> */}
      <Footer />
    </div>
  )
}

export default Product
