import React from 'react'
import Header from './Header'
import Footer from './Footer'
import productmain from './assets/image/product-image-778x476.png'
import Development from './assets/image/Business Development Advice.jpg'
import GoogleBusiness from './assets/image/shop-clothing-clothes-shop-hanger-modern-shop-boutique.jpg'
import Footerbrand from './Footerbrand'
import { Link } from 'react-router-dom'

const Blog = () => {
  return (
    <div>
      <Header name='blog' /> 
      <section className='py-3 pt-4' style={{ backgroundColor: '#f7f7fc' }}>
      <div class="container">
        <div class="d-flex justify-content-between align-items-center align-self-center">
           <h3 className="text-dark fw-600 d-none d-md-block pb-0 ">
           Blog & Best Advise  </h3>
          <ol className='d-flex justify-content-center align-items-center '>
            <li>
               <Link to={'/Home'} className='text-orange'>Home</Link> </li> <span className='mx-2'>/</span> 
            <li> Blog & Best Advise </li>
          </ol>
        </div>
      </div>
    </section> 
            <section className=" mb-35 mt-35 px-3 p-md-0">
        <div className="container p-0">
          <div className="row">
            <div className="col-md-12">
              <div className="shadow-box mb-4 p-25">
                <div className="text-dark">
                  <h2 className='fw-semibold'> Business Development Advice</h2>
                  <p className="mt-10">Conduct thorough market research to identify gaps in the market, assess competition,and understand customer preferences. everage data analytics to inform your business development strategies. Monitor key performance indicators (KPIs) and use data to refine your approach and identify areas for improvement.</p>
                </div>
                <img src={Development}  className="w-50" loading="lazy" alt="What Is A Franchise Opportunity" />
              </div>              
              <div className="row mt-15">
                <div className="col-xl-4 col-lg-4 col-md-6 col-12 mb-20 mb-md-0">
                  <div className="p-3 border rounded   bg-white ">
                    <img src={productmain} loading="lazy" alt="What Is A Franchise Opportunity" />
                    <div className>
                      <h5 className="pt-3">
                        Responsive Website Redesign
                      </h5>
                      <p className="pb-3 pt-2">
                        Evaluate your current website: Identify its strengths, weaknesses, and areas that need improvement.Start designing for mobile devices first. This approach ensures that the essential content and features are prioritized for smaller screens.Condense or reorganize content as needed to maintain a clean and uncluttered design.
                      </p>
                      <button className="btn-main  w-100 py-2 rounded-1  border-0">Read
                        More</button>
                    </div>
                  </div>
                </div> <div className="col-xl-4 col-lg-4 col-md-6 col-12 mb-20 mb-md-0">
                  <div className="p-3 border rounded  bg-white ">
                    <img src={Development} loading="lazy" className="rounded-1" alt="What Is A Franchise Opportunity" />
                    <div className>
                      <h5 className="pt-3">
                        E-Commerce App Development
                      </h5>
                      <p className="pb-3 pt-2">
                        Launch your e-commerce app on relevant app stores.Develop a marketing strategy, including social media campaigns, influencer partnerships, and search engine optimization (SEO)
                        Monitor user feedback and analytics for continuous optimization.</p>
                      <button className="btn-main  w-100 py-2 rounded-1  border-0">Read
                        More</button>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-12 mb-20 mb-md-0">
                  <div className="p-3 border rounded  bg-white ">
                    <img src={GoogleBusiness} loading="lazy" className="rounded-1" alt="What Is A Franchise Opportunity" />
                    <div className>
                      <h5 className="pt-3">
                        Design User Interface (UI/UX):
                      </h5>
                      <p className="pb-3 pt-2">
                        Design an intuitive and visually appealing user interface.
                        Prioritize a seamless navigation experience, clear product displays, and a user-friendly checkout process.
                        Consider mobile responsiveness for a variety of devices.
                      </p>
                      <button className="btn-main  w-100 py-2 rounded-1  border-0">Read
                        More</button>
                    </div>
                  </div>
                </div>
              </div>                              
            </div>             
          </div>
        </div>
      </section >
      <Footerbrand />
      <Footer />
    </div >
  )
}

export default Blog

