

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';  // <----
let firebaseApp;
SetupFirebase();

/**
* Firebase Initialization Function
* This must be called before any firebase query
*/
function SetupFirebase() {
    const firebaseConfig = {
        apiKey: "AIzaSyC2i1pQjYSQj5FmvKaBWMN6hsHB5W5g1G8",
        authDomain: "liontouchwebpark-a5ef9.firebaseapp.com",
        databaseURL: "https://liontouchwebpark-a5ef9-default-rtdb.firebaseio.com",
        projectId: "liontouchwebpark-a5ef9",
        storageBucket: "liontouchwebpark-a5ef9.appspot.com",
        messagingSenderId: "1029426734677",
        appId: "1:1029426734677:web:757b55874f3a47c30a96dc",
        measurementId: "G-LG1ZQ2PDQP"

    };
    // Initialize Firebase
    firebaseApp = firebase.initializeApp(firebaseConfig);
}

export default firebaseApp;