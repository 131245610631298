import { Container } from '@mui/material'
import React from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import logo from './assets/image/Lion Touch logo.png'


const Header = (props) => {
    return (
        <>
            {/* header is a start hear */}
            <div className='d-none d-lg-block'>
                <div className='header_navbar'>
                    <div className='container'>
                        <div className='row'>
                            <div className="col-lg-6 ">
                                <div className='d-flex align-self-center'>
                                    <div className='d-flex align-iteam-center pt-2'>
                                        <i class="lni lni-phone text-light align-self-center me-2"></i>
                                        <a href="tel:+91 8849858625" className='text-light text-start   fw-500'> +91 884 985 8625 </a>
                                    </div>
                                    <div className='d-flex align-iteam-center pt-2'>
                                        <i class="lni lni-envelope text-light align-self-center ps-3 pe-2"></i>
                                        <a href="mailto:liontouchwebpark@gmail.com" className='text-light text-start    fw-500'>liontouchwebpark@gmail.com </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 text-end  ">
                                <span className='text-light align-self center mt-2 fw-500'>
                                    <a href="https://api.whatsapp.com/send?phone=+918849858625&amp;text=Hi+Ghanshyam,+I+want+some+more+information+about+your+services.+Please+reply+soon." target='_blank'><i className="text-light lni lni-whatsapp me-3 "></i></a>
                                    <a href="#"> <i className=" text-light lni lni-facebook-original me-3"></i></a>
                                    <a href="https://www.instagram.com/liontouch_webpark?igsh=MTB4ZWU3ZmNiY3pwdA==" target='_blank'><i className="text-light lni lni-instagram-original me-3"></i></a>
                                    <a href="https://www.linkedin.com/in/liontouch-webpark-a45a052b2" target='_blank'><i className=" text-light lni lni-linkedin-original text-white "></i></a> </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Navbar expand="lg" className="topnavmenu bg-white sticky-top">
                <Container className="container-fluid">
                    <Navbar.Brand href="./" className='navbar-brand m'> <img src={logo} style={{ height: 42 }} /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" >
                        <Nav className="navbar-nav m-auto">
                            <Link to={'/'} className={props.name == 'home' ? 'nav-btns fw-bold m-1  active' : 'nav-btns fw-bold m-1 '}>   Home </Link>
                            <Link to={'/About-Us'} className={props.name == 'About-us' ? 'nav-btns fw-bold m-1  active' : 'nav-btns fw-bold m-1'}>About Us</Link>
                            <Link to={'/Product'} className={props.name == 'Product' ? 'nav-btns fw-bold m-1  active' : 'nav-btns fw-bold m-1'}>Product</Link>

                            <Link to={'/Services'} className={props.name == 'Services' ? 'nav-btns fw-bold m-1  active' : 'nav-btns fw-bold m-1'}>Services</Link>
                            <Link to={'/Blog'} className={props.name == 'blog' ? 'nav-btns fw-bold m-1 active' : 'nav-btns fw-bold m-1'}>Blog</Link>
                            <Link to={'/Career'} className={props.name == 'Carrer' ? 'nav-btns fw-bold m-1 active' : 'nav-btns fw-bold m-1'}>Career</Link>
                            <Link to={'/contact'} className={props.name == 'Contact' ? 'nav-btns fw-bold m-1 active' : 'nav-btns fw-bold m-1'}>Contact</Link>
                        </Nav>
                        <Link to={'/contact'} className='ms-auto' >
                            <p className='btn-history px-4 py-2 mb-0'>Work With Us</p>
                        </Link>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Header