import React, { useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import contactimg from './assets/image/contact-us.jpg';
// import Background from './assets/image/bg-3.jpg';
import firebaseApp from './Firecontact';
import { ToastContainer, toast } from 'react-toastify';
import Footerbrand from './Footerbrand';
import { Link } from 'react-router-dom';

let datac = [];
const Contact = () => {
  // var sectionStyle = {
  //   backgroundImage: "url(" + Background + ")"
  // };
  const [fname, setFname] = useState('')
  const [companynm, setCompany] = useState('')
  const [number, setNumber] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [selectedValue, setSelectedValue] = useState("You are a Business  Man");

  const [Inquriydatad, SetContact] = useState([])

  const validateEmail = (email) => {
    // Use a regex pattern for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    // Use a regex pattern for basic phone number validation
    const phoneRegex = /^[0-9]+$/;
    return phoneRegex.test(phoneNumber);
  };

  const Contactonsubmit = () => {
    let Contactdata = {
      Fullname: fname,
      Company: companynm,
      Email: email,
      Number: number,
      Your: selectedValue,
      Messsage: message,
      id: Date.now(),
      Date: Date()
    }

    if (fname === '') {
      alert('Please enter Full name');
      return;
    }
    if (companynm === '') {
      alert('Please enter company Name');
      return;
    }

    if (!validatePhoneNumber(number)) {
      alert('Please enter a valid phone number 0-9 & 10 Character');
      return;
    }
    if (!validateEmail(email)) {
      alert('Please enter a valid email address');
      return;
    }

    if (message === '') {
      alert('Please enter a message');
      return;
    }

    datac.push(Contactdata)

    // console.log(Contactdata);

    let db = firebaseApp.firestore()
    db.collection("Contact_cli").add(Contactdata)
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
        toast.success('Thank you')

      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });

    SetContact(datac);
    setFname('')
    setEmail('')
    setCompany('')
    setNumber('')
    setSelectedValue('')
    setMessage('')
  }

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <div>
      <Header name='Contact' />
      <ToastContainer />
      <section className='py-3 pt-4' style={{ backgroundColor: '#f7f7fc' }}>
        <div class="container">
          <div class="d-flex justify-content-between align-items-center align-self-center">
            <h3 className="text-dark fw-600 d-none d-md-block pb-0 ">
              Contact
            </h3>
            <ol className='d-flex justify-content-center align-items-center '>
              <li>
                <Link to={'/Home'} className='text-orange'>Home</Link> </li> <span className='mx-2'>/</span>
              <li> Contact </li>
            </ol>
          </div>
        </div>
      </section>
      <section className="pt-35 pb-35 px-2 px-lg-2">
        <div className="container   p-3">
          <div className="row justify-content-center">
            <h2 className="mb-20">Contact Us</h2>
            <p>
              Are you a Business or an entrepreneur interested in starting your own Simplifying complex businesses? <br></br>
              Get in touch with us and we'll get back to you as soon as we can.
            </p>
            <div className="col-md-6">
              <div className="row mt-10">
                <div className="col-md-6">
                  <label className="text-dark mt-2 mb-1" >Full Name</label>
                  <input type="text" className="form-control" onChange={(e) => setFname(e.target.value)} value={fname} />
                </div>
                <div className="col-md-6">
                  <label className="text-dark mt-2 mb-1" >Business Name</label>
                  <input type="text" className="form-control" onChange={(e) => setCompany(e.target.value)} value={companynm} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="text-dark mt-2 mb-1" >Phone Number</label>
                  <input type="text" className="form-control" maxLength={10} onChange={(e) => setNumber(e.target.value)} value={number} />
                </div>
                <div className="col-md-6">
                  <label className="text-dark mt-2 mb-1" >Your Email Address*</label>
                  <input type="email" className="form-control" onChange={(e) => setEmail(e.target.value)} value={email} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <label className="text-dark mt-2 mb-1">You are</label>
                  <select
                    id="inputState"
                    className="form-control"
                    value={selectedValue}
                    onChange={handleSelectChange}
                  >
                    <option value="You are a Business Man">You are a Business Man</option>
                    <option value="You are a Employes">You are a Employes</option>
                    <option value="You are a Developer">You are a Developer</option>
                    <option value="You are a Startup">You are a Startup</option>
                  </select>
                </div>
              </div>
              <div className="row pb-10">
                <div className="col-12">
                  <label className="text-dark mt-2 mb-1">Message</label>
                  <textarea name="message" id="message" onChange={(e) => setMessage(e.target.value)} value={message} rows={4} defaultValue={"Hello My Business is.."} />
                </div>
              </div>
              <div className="row pt-0 ">
                <div className="col">
                  <p className='pb-20'>
                    The personal information collected is processed by the Point Liontouch website which is
                    operated by Infopro Digital Services Limited, a company registered in India and Wales
                    (company number 9925615191).
                  </p>
                  <button type="button" onClick={Contactonsubmit} className="btn-main w-100 w-md-25">
                    Send
                  </button>
                </div>
              </div>
            </div>
            <div className='col-md-6 justify-content-center mt-3 align-self-center '>
            <div className="mapouter" style={{ position: 'relative', textAlign: 'right', height: '500px', width: '100%' }}>
      <div className="gmap_canvas" style={{ overflow: 'hidden', background: 'none!important', height: '100%', width: '100%' }}>
        <iframe
          width="100%"
          height="100%"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=liontouch%20webpark&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          title="Google Map"
        ></iframe>
      </div>
    </div>
            </div>
          </div>
        </div>
      </section>
      <Footerbrand />
      <Footer />
    </div>
  )
}

export default Contact
