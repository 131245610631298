import React from 'react'
import { Link } from 'react-router-dom'
import localapp from './assets/image/Local-app.png'


const Footerbrand = () => {
    return (
        <div>
             <section className="pt-20 bg-gray">
            <div className="container">
                <div className="row ">
                    <div className="col-sm-12 col-md-6 px-2 text-center text-md-start  align-self-center pb-20">
                        <Link to={'/contact'}>
                            <p className="mb-15 fw-semibold font-18">Create Our Website</p>
                        </Link>
                        <h2 className="fw-semibold text-dark pb-1"  >Find Local Customers To Buy
                            <br />
                            In Our <span className="text-orange">Create Our Website</span> &  app
                        </h2>
                        <p className="mb-8 mt-2 font-18">
                            You can Grow your Business With <span className="text-orange">Liontouch Webpark</span> </p>

                    </div>
                    <div className="col-sm-12 col-md-6 align-self-end text-center">
                        <img src={localapp} className='w-50' alt="Find Local Customers" />
                    </div>
                </div>
            </div>
        </section></div>
    )
}

export default Footerbrand