import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
// import Comming from './Comming'
import Home from './Home'
import Services from './Services'
import Blog from './Blog'
import Career from './Career'
import Contact from './Contact'
import Aboutus from './Aboutus'
import Product from './Product'

const Routercontaner = () => {
  return (
    < >
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='' element={<Home />} />
          <Route path='/*' element={< Home/>} />
          <Route path='/home' element={<Home />} />
          <Route path='/About-us' element={<Aboutus />} />
          <Route path='/Services' element={<Services />} />
          <Route path='/Blog' element={<Blog />} />
          <Route path='/Career' element={<Career />} />
          <Route path='/Contact' element={<Contact />} /> 
          <Route path='/Product' element={<Product />} /> 
        </Routes>
      </BrowserRouter>
    </ >
  )
}

export default Routercontaner