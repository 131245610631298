import Header from './Header'
import Accordion from '@mui/material/Accordion';
import { AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import Footer from './Footer';
import Team from './Team';
import Ourservices from './Ourservices';
import Footerbrand from './Footerbrand';
import { Link } from 'react-router-dom';

const Aboutus = () => {

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className='bg-gray'>
      <Header name='About-us' />
      
    <section className='py-3 pt-4' style={{ backgroundColor: '#f7f7fc' }}>
      <div class="container">

        <div class="d-flex justify-content-between align-items-center align-self-center">
           <h3 className="text-dark fw-600 d-none d-md-block pb-0 ">
              About <span className="text-orange">Us
              </span></h3>
          <ol className='d-flex justify-content-center align-items-center '>
            <li>
               <Link to={'/Home'} className='text-orange'>Home</Link> </li> <span className='mx-2'>/</span> 
            <li> About Us</li>
          </ol>
        </div>
      </div>
    </section> 
       
      <Ourservices />
      <section className="py-4 py-md-5">
        <div className="container  px-3 px-md-0">
          <div className="row justify-content-between align-items-centers">
            <div className="col-lg-5 col-md-6 col-12 align-self-center">
              <h5>Our Vision</h5>
              <h4 className="mb-20 text-dark fw-semibold">Simplifying complex businesses</h4>
              <p className="mb-20">Design isn't simply aesthetics. It's giving form to ideas, translating complex thoughts into visual clarity that moves hearts and minds. It's thinking, made visible.
              </p>

              <div className="mt-20 mb-20">
                <a href='https://api.whatsapp.com/send?phone=+918849858625&amp;text=Hi+Ghanshyam,+I+want+some+more+information+about+your+services.+Please+reply+soon.' className="btn-main"><i className="lni lni-whatsapp me-2  "></i>Chat whatsapp</a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6  ">
              <div className="row">
                <div className="col-md-6 col-6   mb-4 text-center">
                  <h4 className="h2 font-weight-normal mb-3">
                    <span className="d-inline-block count1">100 +</span>
                    </h4>
                  <p className="font-weight-normal">Web Designing</p>
                </div>
                <div className="col-md-6 col-6 mb-4 text-center">
                  <h4 className="h2 font-weight-normal mb-3">
                    <span className="d-inline-block count2"  >150 +</span>
                  </h4>
                  <p className="font-weight-normal">Product Delivered</p>
                </div>
                <div className="col-md-6 col-6 mb-4 text-center">
                  <h4 className="h2 font-weight-normal mb-3">
                    <span className="d-inline-block count3"  >12 +</span>
                  </h4>
                  <p className="font-weight-normal">Mobile Applications</p>
                </div>
                <div className="col-md-6 col-6 mb-4 text-center">
                  <h4 className="h2 font-weight-normal mb-3">
                    <span className="d-inline-block count4"  >3 +</span>
                  </h4>
                  <p className="font-weight-normal">Years of Experience</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Team />

      <section className="pt-35 pb-35 px-3 px-md-0 bg-white ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <h4 className=" text-center mb-20  "><i className="lni lni-question-circle text-dark" /><span className="ps-3 text-dark fw-semibold">FAQs for Business</span></h4>
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>
                    Web Applications &Responsive Web Design?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Responsive design isn't just about layout; it's also about providing a positive user experience. Touch-friendly navigation, readable text, and well-spaced interactive elements contribute to a better UX.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography  >What are the steps included in a web development?</Typography>

                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Web development services craft and maintain all kinds of web software. Web developers bring web-based software to life and keep it thriving.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography  >
                    Which is the best company in Surat for web design?
                  </Typography>

                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Liontouch Webpark is the top IT company in Surat, specialising in web development, including e-commerce development, web app development, testing, consulting, and more.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography>Why is web design important for businesses and individuals?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Think of your website as the center attraction of your brand. It's where you showcase your core values, products, and services in a way that's both informative and visually appealing.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                >
                  <Typography>Why is mobile app development important?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Understand what resonates with your customers, how they navigate your brand, and where their purchasing power lies. Use these insights to personalize experiences, refine your marketing, and steer your business towards hypergrowth.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel6bh-content"
                  id="panel6bh-header"
                >
                  <Typography>What does it cost to develop a mobile app?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <li>The experienced developers command more, but their expertise brings magic to your app.</li>
                    <li>
                      Different tools require different skills, and specialized technologies might come with a premium.
                    </li>
                    <li>The feature whistle adds its tune to the price tag. The more functionalities you desire, the more meticulous the development becomes.</li>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      <Footerbrand />
      <Footer />
    </div >
  )
}

export default Aboutus
